import React from 'react'

const DreamMaster = () => {
	return (
		<div
			className='h-screen w-screen bg-cover bg-center bg-no-repeat relative'
			style={{
				backgroundImage: `url("/Vector.png")`,
			}}>
			<div
				className=' w-screen absolute'
				style={{ backgroundImage: `url("/Clip-path-group.png")` }}>
				<div className='px-10 z-50'>
					<img
						className='fixed  md:h-80 z-50'
						src='/dori.png'
						alt=''
					/>
					<div
						className='text-white'
						style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
						<div className='flex items-center gap-10 lg:pt-10'>
							<img
								className=''
								style={{ width: '50px', height: '50px' }}
								src='/bars.png'
								alt=''
							/>
							<div>
								<span>Personal</span>
								<h1 className='text-4xl font-bold font-[Aclonica]'>Dream Master</h1>
								<p className='text-sm'>
									Greeting! Tell me what you dreamed about last night and I will decipher your life
									for you
								</p>
							</div>
						</div>
						<div className='flex flex-row gap-10'>
							<button
								className='bg-white text-red-600 px-10 py-3 rounded-md mt-10 font-bold flex flex-row'
								type=''>
								<img
									className='text-red-600 mr-2'
									src='/majesticons_edit-pen-2.svg'
									alt=''
								/>
								START USING
							</button>
							<button
								className='bg-transparent text-white border border-white px-10 py-3 rounded-md mt-10 font-bold flex flex-row'
								type=''>
								<img
									className='text-white mt-1 mr-2'
									src='/circle-letter-i.svg'
									alt=''
								/>
								Example
							</button>
						</div>
						<div
							className='flex items-center gap-10 pt-10 justify-center z-50'
							style={{ height: '70vh' }}>
							{/* Cards start here */}
							<div
								className='w-1/2 p-5 bg-white text-black z-50 rounded-xl'
								style={{ height: '330px' }}>
								<div className='flex items-center gap-5'>
									<span className='bg-red-600 rounded-full p-1'>
										<img
											className='h-6 w-6  '
											src='/okIcon.svg'
											alt=''
										/>
									</span>

									<div>
										<h3 className='text-xl xlg:text-2xl font-medium'>Tell me About Your dream</h3>
										<p className='text-sm'>A sentence or paragraph describing your dream.</p>
									</div>
								</div>
								<div className='z-50 relative'>
									<span className='text-sm font-medium absolute top-2 bg-white ml-6 px-2'>
										My Dream
									</span>
									<span className='text-sm absolute top-2 right-3 bg-white px-2 mr-6'>
										0/300 chars
									</span>

									<textarea
										className='w-full  mt-5 z-50 border border-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-transparent p-5 rounded-xl'
										name=''
										id=''
										placeholder='I dreamed about....'
										cols='15'
										rows='4'></textarea>
									<button
										className='bg-red-500 text-white px-5 py-3 rounded-md mt-10 font-bold flex float-right flex-row'
										type=''>
										<img
											className='text-white mr-3'
											src='/pencel.svg'
											alt=''
										/>
										Perform Request
									</button>
								</div>
							</div>
							<div
								className='w-1/2 p-5 bg-white text-black z-50 pb-5 rounded-xl'
								style={{ height: '330px' }}>
								<div className='relative'>
									<img
										style={{ left: '-50px', top: '-60px', height: '100px' }}
										className='absolute'
										src='/OBJECTS.png'
										alt=''
									/>
									<img
										style={{ left: '-25px', top: '-50px', height: '65px' }}
										className='absolute'
										src='/Group2.png'
										alt=''
									/>
								</div>
								<div className='ml-28'>
									<h3 className='text-xl xlg:text-2xl font-medium'>
										Dream Master’s Interpretation
									</h3>
									<p className='text-sm'>What does your dream mean?</p>
								</div>
								<div
									className='flex justify-between md:h-4/6 h-3/4 lg:h-3/4 items-end w-full'
									style={{}}>
									<img
										height={30}
										width={30}
										src='/ph_copy-simple-bold.svg'
										alt=''
									/>

									<img
										className=''
										height={30}
										width={30}
										src='/cirlce-letter-i-gray.svg'
										alt=''
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className=' h-1/2 w-screen bg-no-repeat absolute bottom-0 bg-cover bg-center z-0'
					style={{ backgroundImage: `url("/Group.png")` }}></div>
			</div>
		</div>
	)
}

export default DreamMaster
