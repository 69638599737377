import example from './example';

const TOOLS = [

	// codeInterpret,
	// intro,
	// jobad,
	// summarize,
	// helloworld,
	example,

]

export default TOOLS
