import styled, { css, keyframes } from 'styled-components'

export const IconLight = ({ className }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className={className || 'h-6 w-6'}
		fill='none'
		viewBox='0 0 24 24'
		stroke='currentColor'>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={2}
			d='M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z'
		/>
	</svg>
)

export const IconRedo = ({ className }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className={className || 'h-6 w-6'}
		fill='none'
		viewBox='0 0 24 24'
		stroke='currentColor'>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={2}
			d='M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z'
		/>
	</svg>
)

export const IconOutline = ({ className }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className={className || 'h-6 w-6'}
		viewBox='0 0 20 20'
		fill='currentColor'>
		<path d='M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z' />
	</svg>
)

export const IconPeople = ({ className }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className={className || 'h-6 w-6'}
		fill='none'
		viewBox='0 0 24 24'
		stroke='currentColor'>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={2}
			d='M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z'
		/>
	</svg>
)

export const IconDocument = ({ className }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className={className || 'h-6 w-6'}
		fill='none'
		viewBox='0 0 24 24'
		stroke='currentColor'>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={2}
			d='M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z'
		/>
	</svg>
)

export const IconDashboard = ({ className }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className={className || 'h-6 w-6'}
		fill='none'
		viewBox='0 0 24 24'
		stroke='currentColor'>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={2}
			d='M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10'
		/>
	</svg>
)

export const IconFeatures = ({ className }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className={className || 'h-6 w-6'}
		fill='none'
		viewBox='0 0 24 24'
		stroke='currentColor'>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={2}
			d='M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z'
		/>
	</svg>
)

export const IconMagic = ({ className }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className={className || 'h-6 w-6'}
		fill='none'
		viewBox='0 0 24 24'
		stroke='currentColor'>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={2}
			d='M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z'
		/>
	</svg>
)

export const IconEye = ({ className }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className={className || 'h-6 w-6'}
		fill='none'
		viewBox='0 0 24 24'
		stroke='currentColor'>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={2}
			d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
		/>
		<path
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={2}
			d='M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z'
		/>
	</svg>
)

export const Loader = ({ active, hidden, ...props }) => (
	<Spinner
		active={active}
		{...props}>
		<Bounce
			active={active}
			hidden={hidden}></Bounce>
		<BounceTwo
			active={active}
			hidden={hidden}></BounceTwo>
		<BounceThree
			active={active}
			hidden={hidden}></BounceThree>
	</Spinner>
)

const Spinner = styled.div`
	width: 2.5em;
	height: 2.5em;
	position: relative;
	display: inline-block;
	${(props) =>
		!props.active
			? css`
					display: none;
			  `
			: null}
`

const bounce = keyframes`
  0%,100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }

  50% {
    transform: scale(1.0);
      -webkit-transform: scale(1.0);
  }
`

const Bounce = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #10b981;
	opacity: 0.5;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: ${bounce} 3s infinite ease-in-out;
	animation: ${bounce} 3s infinite ease-in-out;
`

const BounceTwo = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #3d84f6;
	opacity: 0.5;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: ${bounce} 3s infinite ease-in-out;
	animation: ${bounce} 3s infinite ease-in-out;
	animation-delay: -1s;
`

const BounceThree = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #3d84f6;
	opacity: 0.5;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: ${bounce} 3s infinite ease-in-out;
	animation: ${bounce} 3s infinite ease-in-out;
	animation-delay: -2s;
`

export default IconEye
